import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./productSlider.css";
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { addToCart } from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify";
import EditQuantityButton from "../../shared-components/editQuantityButton/editQuantityButton"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const ProductSlider = (props) => {

  const authToken = localStorage.getItem("authToken") || ""
  let navigate = useNavigate();
  const [disableQtyButton, setDisableQtyButton] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    largetablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const gotoProductPage = (data) => {
    // const name = data.product_name.replace(/ /g, "-");
    const name = data.item_slug.replace(/ /g, "-");
    navigate(`/product/${name}/${data.product_id}`);
  };

  const decrement = (data) => {
    if (data.quantity > 0) {
      const copyListData = [...props.listData]

      for (let i = 0; i < copyListData.length; i++) {
      const items = copyListData[i].items
      const selectedItem = items.filter((v) => v.product_id === data.product_id)
      if(selectedItem.length){
        selectedItem[0].quantity = selectedItem[0].quantity - 1
        addProductToCart(selectedItem[0])
      }
      }
      props.setListData(copyListData)

    }
  }

  const increment = (data) => {

    if (data.quantity < data.current_stock && inputValue < data.current_stock) {
      const copyListData = [...props.listData]
      
      let repeat = false
      for (let i = 0; i < copyListData.length; i++) {
        const items = copyListData[i].items
        const selectedItem = items.filter((v) => v.product_id === data.product_id)

        if (inputValue && selectedItem.length) {
          if(repeat)
          {
            selectedItem[0].quantity = inputValue
          }
          else
          {
            repeat=true
            selectedItem[0].quantity = inputValue
            addProductToCart(selectedItem[0])
          }
        }
        else if(selectedItem.length){
          if(repeat)
          {
            selectedItem[0].quantity = selectedItem[0].quantity + 1
          }
          else
          {
            repeat=true
            selectedItem[0].quantity = selectedItem[0].quantity + 1
            addProductToCart(selectedItem[0])
          }
        }
      }

      props.setListData(copyListData)

    }
    else {
      toast.error("This much quantity is not in stock.")
    }
  }

  const addBtnHandler = (index, data) => {
    if (authToken !== '') {
      increment(data)
    }
    else {
      toast.error("Please Login First", { autoClose: 1200 })
    }
  }

  const addProductToCart = async (productData) => {
    setDisableQtyButton(true)
    const formData = new FormData()
    formData.append("offer_price", productData.discount_price)
    formData.append("rate", Number(productData.mrp))
    formData.append("quantity", productData.quantity)
    formData.append("product_id", productData.product_id)

    const response = await addToCart(formData)
    if (response.ErrorCode === 0) {
      // toast.success("Cart updated successfully.", { autoClose: 1000 })
      // props.updateListData();
      props.getCartItemsCount()
      // setDisableQtyButton(false)
    }
    else if (response && response.message === "Unauthenticated.") {
      toast.error("Please login first.", { autoClose: 1200 })
    }

  }

  return (
    <>
      <div className="productWrapper">
        {props?.listData ? props?.listData.map((data, index) => {
          return (
            <div key={index}>
              {data.type === "product_carousel" && data.items.length ? <>
                <h4 className="title py-4 font_heading">
                  {data.carousel_name}
                   { data?.id && <span onClick={()=>{navigate(`/carousel/${data.id}`)}} className="float-right font_basic">See All</span>}
                </h4>
              <div className="productSlider">
                <Carousel responsive={responsive} swipeable draggable>
                  {data.items.map((carouselData, index) => {
                     

                    return (
                      <>
                        {carouselData.product_image ? <div
                          className="productCard cursor-pointer relative hover:border-lime-500 mb-4 px-3"
                          key={index}
                        >

                        {carouselData.item_discount && carouselData.item_discount.split(".",1)!='0' &&

                          <div className="top_notch absolute rounded-r-lg left-0 top-4 text-xs pl-2 text-white">
                           <p className="m-0 font_basic">{`${carouselData.item_discount.split(".",1)}% Off`}</p>
                          </div>
                          
                          
                          }

                          <div className="product_img flex justify-center">
                            <img
                              alt="product_image"
                              src={carouselData.product_image}
                              loading="lazy"
                              className="block w-full"
                              onClick={() => gotoProductPage(carouselData)}
                            />
                          </div>
                          <p
                            className="m-0 product_name font_basic"
                            onClick={() => gotoProductPage(carouselData)}
                          >
                            {carouselData.product_name}
                          </p>
                          {carouselData?.packing_info?
                          <p>
                              {carouselData?.packing_info}
                          </p>                    
                          :null
                          }
                          {carouselData?.variants &&  carouselData?.variants.length > 0 ?
                            <div>                        
                              <b>Available Sizes</b>
                              <div class="flex flex-wrap font_paragraph variants_options variant_in_slider">
                                  {carouselData?.variants.map((details, index) => (
                                      <span onClick={()=>{                  
                                          navigate({ pathname: `/product/${details.product_slug}/${details.product_id}` });
                                      }}
                                      className={carouselData?.variant_combo==details.variant_combination?"active":null}                                
                                      > {details.variant_combination}</span>
                                  ))} 
                              </div>            
                            </div>            
                            :null
                          }

                          <div className="bottom_items absolute bottom-2 left-0 w-full px-3">
                            {/* <span className="gray_text font_family_common"> */}
                              {/* {carouselData.quantity ? carouselData.quantity : 0} */}
                            {/* </span> */}

                            {/* {carouselData?.["group_price"] ?
                            <div dangerouslySetInnerHTML={{ __html: carouselData?.["group_price"] }} className="small-text text-green-700 mt-3" />
                            :null
                            } */}

                            <div className="card_bottom flex justify-between mt-1">
                              <div className="product_price w-6/12">
                                <span className="discount_price text-base m-0 block font_basic">
                                ₹{(Math.floor(carouselData.discount_price))}
                                </span>
                                <span className="actual_price gray_text text-sm line-through font_family_common">&nbsp;
                                {parseFloat(carouselData.mrp) > parseFloat(carouselData.discount_price) ? 
                                <span>
                                  ₹ {Math.floor(carouselData.mrp)} 
                                </span>
                                :''
                                }
                                </span>
                              </div>

                              {carouselData.quantity > 0 ?
                              <EditQuantityButton
                                decrement={decrement}
                                increment={increment} 
                                productData={carouselData} 
                                inputValue={inputValue} 
                                setInputValue={setInputValue}
                                disableQtyButton={disableQtyButton}
                              />
                                :

                                <>
                                  {carouselData.current_stock <= 0 ?
                                    <p className="m-0 d-flex justify-content-center align-items-center font-semibold py-1 font_10 text-danger text-uppercase"> Out Of Stock</p>
                                    :
                                    <button
                                      className="w-8/12 h-10 text-center text-green font-semibold py-1 px-3 card_add_btn rounded-lg"
                                      onClick={() => { addBtnHandler(index, carouselData) }}
                                    >
                                      ADD
                                    </button>}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                          : 
                        null}
                      </>
                    );
                  })}
                </Carousel>
              </div>
              </> 
              
              :
              
              <div className="banner_carousel mt-4">
                  {data.type === "banner_carousel" && data.items.map((banner, index)=>{


return <div className={data.banner_items?`banner_container my-4 cursor`:`banner_container my-4`} key={banner.id} onClick={data.banner_items?  ()=>{navigate(`/carousel/${data.id}`)}:false} >
                      <img src={banner.desktop_banner} alt="banner_img" />
                    </div>
                   })}
                
              </div>
               }
            </div>
          );
        }) : null} 
      </div>
      <style jsx>
        {`
          .title {
            color: rgb(28, 28, 28);
            font-size: 24px;
            font-family: Okra-Medium;
            font-weight: 600;
            line-height: 32px;
          }
          .title span {
            line-height: 32px;
            font-size: 20px;
            color: rgb(12, 131, 31);
            cursor: pointer;
          }

          .productCard {
            min-height: 356px;
            width: 200px;
            border: 1px solid lightgray;
            border-radius: 8px;
            padding: 12px;
          }

          .productCard .top_notch {
            height: 32px;
            display:flex;
            align-items:center;
            background: #256fef;
          }

          .productCard .top_notch p {
            margin-top: 2px;
          }

          .product_img {
            width: 100%;
          }

          .product_img img {
            height: 174px;
            width: 174px;
          }

          .productCard .product_name {
            font-weight: 380;
            font-size: 15px;
            line-height: 20px;
          }

          .gray_text {
            color: #9ca3b0;
            font-weight: 300;
            font-size: 13px;
          }

          .popup-content {
            border-radius: 10px;
            width: 40%;
            }

          .productSlider .react-multi-carousel-list .react-multiple-carousel__arrow{
            z-index:998 !important;
          }

          @media screen and (max-width:600px){
            .productCard {
            min-height: 350px;
            width: 172px;
            border: 1px solid lightgray;
            border-radius: 8px;
            padding: 12px;
          }
          .productCard .product_name
          {
            font-size:10px;
          }


          }
        `}
      </style>
    </>
  );
};

export default ProductSlider;
