import CallApi from "./apiCallingSetup";

// const API_BASE_URL = "https://dev.techstreet.in/ecomadmin/public/api/v1";
const API_BASE_URL = "https://aldaadmin.techstreetconsulting.com/api/v1";

const authToken = localStorage.getItem("authToken") || ""

export const carouselData = async (data) => {
  const url = `${API_BASE_URL}${authToken ? "/store/carousels/list" : "/pincode/carousels/list"}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const categoryData = async (data) => {
  const url = `${API_BASE_URL}/category`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const getSubCategory = async (data) => {
  const url = `${API_BASE_URL}/subcategory`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const categoryProductList = async (data) => {
  const url = `${API_BASE_URL}${authToken ? "/category-productlist" : "/pincode-productlist"}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const searchProductList = async (data) => {
  const url = `${API_BASE_URL}${authToken ? "/products":"/pincode-products"}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const getTrendingProducts = async (data) => {
  const url = `${API_BASE_URL}/pincode-featured-product`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
};

export const loginOtpGenerate = async (data) => {
  const url = `${API_BASE_URL}/otp`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const submitOtpForLogin = async (data) => {
  const url = `${API_BASE_URL}/verify/otp`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const addUserAddress = async (data) => {
  const url = `${API_BASE_URL}/address/add`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const userAddressList = async (data) => {
  const url = `${API_BASE_URL}/address/list`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const editUserAddress = async (data) => {
  const url = `${API_BASE_URL}/address/update`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const deleteUserAddress = async (data) => {
  const url = `${API_BASE_URL}/address/delete`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const updateUserAddress = async (data) => {
  const url = `${API_BASE_URL}/address/update`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const defaultUserAddress = async (data) => {
  const url = `${API_BASE_URL}/address/default`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const registerUser = async (data) => {
  const url = `${API_BASE_URL}/register`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const userProfile = async (data) => {
  const url = `${API_BASE_URL}/profile`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const editProfile = async (data) =>{
  const url = `${API_BASE_URL}/user/profile-update`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}


export const editProfileImage = async (data) =>{
  const url = `${API_BASE_URL}/profile_update`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const addToCart = async (data) =>{
  const url = `${API_BASE_URL}/cart-add`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
    hidePopup: true
  });
  return response.data;
}
export const cartList = async (data) =>{
  const url = `${API_BASE_URL}/cart`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const deleteItemFromCart = async (data) =>{
  const url = `${API_BASE_URL}/cart-delete`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const emptyCart = async (data) =>{
  const url = `${API_BASE_URL}/cart-empty`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const cartItemsCount = async (data) =>{
  const url = `${API_BASE_URL}/cart-badge`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const cashOnDelivery = async (data) =>{
  const url = `${API_BASE_URL}/placeorder-cod`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const createOrder = async (data) =>{
  const url = `${API_BASE_URL}/placeorder`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const transactionUpdate = async (data) =>{
  const url = `${API_BASE_URL}/transaction-update`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const orderOpen = async (data) =>{
  const url = `${API_BASE_URL}/orders/open`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const orderDetails = async (data) =>{
  const url = `${API_BASE_URL}/order-detail`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const completedOrder = async (data) =>{
  const url = `${API_BASE_URL}/orders/completed`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const cancelOrder = async (data) =>{
  const url = `${API_BASE_URL}/order-cancel`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const productDetails = async (data) =>{
  const url = `${API_BASE_URL}${authToken ? '/product-details' : '/product-details-without-auth'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const orderItemDetails = async (data) =>{
  const url = `${API_BASE_URL}/order-item-detail`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const getRequestQuestion = async (data) =>{
  const url = `${API_BASE_URL}/get-request-question`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const getProductQuestion = async (data) =>{
  const url = `${API_BASE_URL}/get-product-question`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const returnOrder = async (data) =>{
  const url = `${API_BASE_URL}/return`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const returnReplacement = async (data) =>{
  const url = `${API_BASE_URL}/return-replacement-request-list`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const recentOrders = async (data) =>{
  const url = `${API_BASE_URL}${authToken && "/recent-order-items"}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const pincodeBrand = async (data) =>{
  const url = `${API_BASE_URL}${authToken ? '/brand/list': '/brand/list-pincode'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const brandProductList = async (data) =>{
  const url = `${API_BASE_URL}${authToken ? '/brand/products/list': '/pincode/brand/products/list'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const carouselProductList = async (data) =>{
  const url = `${API_BASE_URL}${authToken ? '/carousels/item/list': '/carousels/item/list'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const fetchSliderImages = async (data) =>{
  // const url = `${API_BASE_URL}${authToken ? '/store-setting' : '/banners'}`;
  const url = `${API_BASE_URL}${authToken ? '/store-setting' : '/banners'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const fetchStoreSettings = async (data) =>{
  // const url = `${API_BASE_URL}${authToken ? '/store-setting' : '/banners'}`;
  const url = `${API_BASE_URL}${authToken ? '/store-setting' : '/store-setting-pincode'}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}


export const customerRequestProduct = async (data) =>{
  const url = `${API_BASE_URL}/customer-request-product`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const AddUserFeedback = async (data) =>{
  const url = `${API_BASE_URL}/userfeedback/add`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const ReviewQues = async (data) =>{
  const url = `${API_BASE_URL}/feedbackquestion/${data.id}`;
  let response = await CallApi({
    url,
    method: "POST",
    data: {},
  });
  return response.data;
}

export const reOrder = async (data) =>{
  const url = `${API_BASE_URL}/cart-add-recent-order`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  });
  return response.data;
}

export const detectAddress = async(data)=>{
  const url = `${API_BASE_URL}/get-google-address` ;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  })

  return response.data ;
}
export const rating = async(data)=>{
  const url = `${API_BASE_URL}/rating/list` ;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  })

  return response.data ;
}

export const userRatingAdd = async (data)=>{
  const url = `${API_BASE_URL}/userrating/add`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  })
  return response.data ;
}

export const pincodeDeliveryEstimate = async (data)=>{
  const url = `${API_BASE_URL}/pincode-estimate-delivery`;
  let response = await CallApi({
    url,
    method: "POST",
    data: data,
  })
  return response.data ;
}