
import React,{useState, useRef} from "react"
import "./categoryHeader.css"
import {Link} from "react-router-dom"
import arrow from "../../../assests/arrow_drop.png"
import UseOutsideClick from "../detectOutsideClick/useOutSideClick"


const CategoryHeader = ({categoryHeaderData, moreData, categoryId, setCategoryId}) =>{

    const ref = useRef()
    const [showDropdown, setShowDropdown] = useState(false)
    
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    
    }

    UseOutsideClick(ref,()=>{
        if(showDropdown){
            setShowDropdown(false)
        }
    })

    return <>
        <div className="category_header row m-0 border-bottom d-none d-lg-flex">
            <div className="col-0 col-lg-1 bg-white"></div>
            <div className="col-12 col-lg-10 pt-3 pb-1 d-flex justify-content-center bg-white">
                <ul className="list-unstyled d-flex flex-wrap m-0 py-1">
                    {(categoryHeaderData || []).map((data, index) => {
                        return <li className="list-item" onClick={() => { setCategoryId(data.id) }} key={index}>
                            <Link to="#" className={`header_item_link text-decoration-none fs-6 px-3 py-2 font_basic text-secondary ${categoryId === data.id ? "active_div" : ''}`}>{data.category_name ? data.category_name : null}</Link>
                        </li>
                    })}
                    {moreData.length>0?
                        <div class="_more_dropdown more_dropdown position-relative">
                            <span className=" text-secondary cursor-pointer d-flex font_basic" onClick={toggleDropdown}>
                                More
                                <img src={arrow} alt="" width="17px" className="mt-1 ms-1" />
                            </span>
                            {showDropdown ? <ul className="position-absolute bg-white dropdown_custom_menu" aria-labelledby="dropdownMenuButton1" ref={ref}>
                                {moreData.map((val, index) => {
                                    return <li><a className={`dropdown-item py-2 px-3 text-secondary font_basic ${Number(categoryId) === val.id ? 'dropdown_active_border' : "border-bottom"}`} href="#" onClick={() => { setCategoryId(val.id) }}>{val.category_name}</a></li>
                                })}
                            </ul> : null}
                        </div>
                    :null
                    }

                </ul>
            </div>
            <div className="col-0 col-lg-1 bg-white"></div>
        </div>
    </>
}

export default CategoryHeader