import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React,{useState} from "react" ;
import "./imagesMiniCarousel.css"
import productImage from "../../../assests/product_img.png"

const ImagesMiniCarousel = ({productData, activeImage, setActiveImage}) =>{


    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };

    return(
        <>
            <Carousel
                  responsive={responsive}
                  arrows={true}
                >
                <div className={`bottom_image border rounded p-2 cursor-pointer ${activeImage === '' ? "active_image" : ''}`}>
                      <img 
                        src={productData.product_image ? productData.product_image : productImage} 
                        alt="" 
                        className="d-block w-100" 
                        onClick={()=>{setActiveImage('')}}
                        />
                    </div>
                  {(productData.multiple_images || []).map((v, index) => {
                    return <div className={`bottom_image border rounded p-2 cursor-pointer ${activeImage === index ? "active_image" : ''}`} key={index}>
                      <img 
                        src={v.image ? v.image : productImage} 
                        alt="" 
                        className="d-block w-100" 
                        onClick={()=>{setActiveImage(index)}}
                        />
                    </div>
                  })}

                </Carousel>
        </>
    )
}

export default ImagesMiniCarousel ;