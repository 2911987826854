import Superfast_Delivery from "../../../assests/Superfast-Delivery.png"
import Best_Prices_Offers from "../../../assests/Best-Prices-Offers.png"
import Wide_Assortment from "../../../assests/Wide-Assortment.png"
import Easy_Returns_Refund from "../../../assests/Easy-Returns-Refund.png"

const ProductPageRightDetails = ({productData}) => {
    return (<>



<div className="product-details pr-5">
                <div className="product-detail-container">
                  <h2 class="mt-4 font_heading">
                    Product Details
                  </h2>
                  <div>
                  {/* <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Product Name</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                      {productData && productData.product_name}
                    </div>
                  </div>
                  <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Short Description</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                      {productData ? productData.short_description : "---"}
                    </div>
                  </div> */}


                  {/* <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Offer Price</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                    ₹{productData ? productData.discount_price : "00"}
                    </div>
                  </div> */}
                  {/* <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>MRP Price</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13 text-decoration-line-through">
                    ₹{productData ? productData.mrp : "00"}
                    </div>
                  </div> */}
                  <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC long_description">
                    <div dangerouslySetInnerHTML={{ __html: productData.long_description }} class="font_paragraph"/>
                  </div>
                  
                  {productData?.other_details ? productData?.other_details.map((details, index) => (
                    <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>{details.title}</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                    {details.description ? details.description :  '---'}
                    </div>
                  </div>
                
                  )): null} 
                  {/* <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Other Details</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                    {productData.other_details ? productData.other_details[0].description :  '---'}
                    </div>
                  </div> */}
                  {/* <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
                    <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
                      <span>Group Pricing</span>
                    </p>
                    <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
                    {productData.group_price ? `₹ ${productData.group_price}` :  '---'}
                    </div>
                  </div> */}

                  </div>
                </div>
              </div>


        {/* <div className="why-shop-from">
            <div class="GrofersPromises__PDPPromiseContainer-sc-1uzjcwr-0 jOCFCR">
                <div class="GrofersPromises__PDPPromiseHeader-sc-1uzjcwr-1 bWmbbS font_basic">
                    Why shop from Instadent?
                </div>
                <div className="first-row">
                    <div className="column">
                        <img
                            alt="express delivery"
                            src={Superfast_Delivery}
                            loading="lazy"
                            class="icon-image"
                        />

                        <div class="display--table-cell vertical-align--middle">
                            <div class="first-row__name  color-black font_family_common font_13 fw-bold">
                                Superfast Delivery
                            </div>
                            <div class="first-row__description font_paragraph">
                                Get your order delivered to your doorstep at the
                                earliest from dark stores near you.
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <img
                            alt="express delivery"
                            src={Best_Prices_Offers}
                            loading="lazy"
                            class="icon-image"
                        />

                        <div class="display--table-cell vertical-align--middle">
                            <div class="first-row__name  color-black font_family_common font_13 fw-bold">
                                Best Prices &amp; Offers
                            </div>
                            <div class="first-row__description font_family_common font_16">
                                Cheaper prices than your local supermarket, great
                                cashback offers to top it off.
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <img
                            alt="express delivery"
                            src={Wide_Assortment}
                            loading="lazy"
                            class="icon-image"
                        />

                        <div class="display--table-cell vertical-align--middle">
                            <div class="first-row__name  color-black font_family_common font_13 fw-bold">
                                Wide Assortment
                            </div>
                            <div class="first-row__description font_family_common font_16">
                                Choose from 5000+ products across food, personal care,
                                household &amp; other categories.
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <img
                            alt="express delivery"
                            src={Easy_Returns_Refund}
                            loading="lazy"
                            class="icon-image"
                        />

                        <div class="display--table-cell vertical-align--middle">
                            <div class="first-row__name  color-black font_family_common font_13 fw-bold">
                                Easy Returns
                            </div>
                            <div class="first-row__description font_family_common font_16">
                                Not satisfied with a product? Return it at the doorstep & get a refund within hours.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}




    </>)
}

export default ProductPageRightDetails;