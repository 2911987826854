import React, { useState, useRef, useEffect, useMemo } from "react"
import mobileLogo from "../../../assests/mobile_logo.png"
import logo from "../../../assests/logo.png"
import orderSucess from "../../../assests/success_order.jpeg"
import "./header.css"
import {toast} from "react-toastify"
import {
  searchProductList,
  loginOtpGenerate,
  cartList,
  userAddressList,
  customerRequestProduct,
  cartItemsCount,
  detectAddress,
  pincodeDeliveryEstimate,
  getTrendingProducts
}
  from "../../../utils/apiUrlsSetup";
import SideCartView from "../../shared-components/sideCartView/sideCartView"
import { useNavigate, Link } from "react-router-dom"
import UseOutsideClick from "../detectOutsideClick/useOutSideClick";
import CommonModal from "../commonModal/commonModal";
import ArrowDown from "../../../assests/solid_arrow_down.png"
import userImage from "../../../assests/user_img.png"
import proImage from "../../../assests/product_img.png"
import noData from "../../../assests/data_unavailable.webp"
import AddressModal from "../../shared-components/address/addressModal"
import LogoutPopup from "../logoutPopup/LogoutPopup";
import Loader from "../loader/loader"

const Header = (props) => {

  const loginNumber = localStorage.getItem("loginNumber") || "";
  const authToken = localStorage.getItem("authToken") || "";
  const pincode = localStorage.getItem("pincode") || "";
  const [address,setAddress] = useState( localStorage.getItem("address") || "" ); 
  const userDetail = JSON.parse(localStorage.getItem("userDetails"))
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate()

  const ref = useRef()

  const [list, setList] = useState('')
  const [showList, setShowList] = useState(false)
  const [trending,setTrending] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [isOtpReceived, setIsOtpReceived] = useState(false)
  const [otp, setotp] = useState('')
  const [isUserLogin, setIsUserLogin] = useState(false)
  const [accountClicked, setAccountClicked] = useState(false);

  const [showCart, setShowCart] = useState(false)
  const [cartListData, setCartListData] = useState({})

  const [showHeaderAddress, setShowHeaderAddress] = useState(false)
  const [addAddressModal, setAddAddressModal] = useState(false)
  const [headerAddressList, setHeaderAddressList] = useState([])
  const [suggestedproduct, setSuggestedProduct] = useState({})
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [deliveryTime,setDeliveryTime] = useState(null);
  const [dataUnAvailable,setDataUnAvailable] = useState(false);

  const [orderId, setOrderId] = useState('')
  const [badgeCount, setBadgeCount] = useState()
  const [badgePrice, setBadgePrice] = useState()

  useEffect(()=>{
    if(addAddressModal){
      setShowHeaderAddress(true)
    }
  },[addAddressModal])


  useEffect(() => {
    getHeaderAddressList()
  }, [authToken])

  useEffect(()=>{
    if(pincode){
      pincodeDeliveryEstimate({pincode}).then(({ItemResponse})=>{
        setDeliveryTime(ItemResponse);
      })
    }
  },[pincode])

  useEffect(()=>{
    if( isFinite( props.badgeCount)){
      setBadgeCount(props.badgeCount)
    }
    
  },[props.badgeCount])

  useEffect(()=>{
    if(props.badgePrice){
      setBadgePrice(props.badgePrice)
    }
    
  },[props.badgeCount])

  useEffect(()=>{
    if(props.badgePrice){
      setBadgePrice(props.badgePrice)
    }
    
  },[props.badgePrice])
  
  useEffect(()=>{
    if(authToken){
      getCartListData()
    }
  },[])
  
  useEffect(()=>{
    if(props.reorderOpenCart){
      // setShowCart(true)
    }
  },[props.reorderOpenCart])

  useEffect(()=>{
    if (!authToken && !pincode){
        setShowHeaderAddress(true)
    }
  },[])

  useEffect(()=>{
    setAddress(localStorage.getItem('address') || "")
  },[localStorage.getItem('address')])


  const getCoordinates = ()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(getPosition)
    }
    else{
      toast.error("geolocation is not supported")
    }
  }

  const getPosition = async (position) =>{

    const {latitude, longitude} = position.coords

    const formData = new FormData()
    formData.append("latitude", latitude)
    formData.append("longitude", longitude)

    const response = await detectAddress(formData);
    if(response.ErrorCode === 0){
      localStorage.setItem("pincode", response.Response.pincode);
      localStorage.setItem("address", response.Response.address);
      setAddress(response.Response.address);
      setShowHeaderAddress(false)
      checkDeliveryAvailablity(response.Response.pincode);

    }
  }

  const getCartListData = async () => {

    const response = await cartList({})
    if (response.ErrorCode === 0) {
      // console.log(response?.Response)
      setCartListData(response?.Response)
    }
    else if (response.ErrorCode === -101) {
      // console.log(response?.Response)
      setCartListData({})
    }
  }

  const getCartItemsCount = async() =>{
    const res = await cartItemsCount({})
    if(res.ErrorCode === 0){
        setBadgeCount(res?.Data?.total_count)
        setBadgePrice(res?.Data?.total_price)
    }
}

  const getSearchProducts = async (searchTitle) => {

    const pincodeObj = {
      search: searchTitle,
      pincode: 201301
    }

    const obj = {
      search: searchTitle,
    }

    let data = await searchProductList(authToken ? obj : pincodeObj)
    if (data && data.ErrorCode === 0 && data.ItemResponse) {
      setList(data.ItemResponse)

      if (searchTitle !== '') {
        setShowList(true)
      }
      else {
        setShowList(false)
      }
      setTrending(false)
    }
  }

  const getTrendingProdu = async () => {
    setShowLoader(true)
    let data = await getTrendingProducts({pincode: 201301});
    if (data && data.ErrorCode === 0 && data.Response) {
      setList(data.Response.data.length>15 ? data.Response.data.slice(15) : data.Response.data)
      setShowList(true)
      setTrending(true)
    }
    setShowLoader(false)
  }

  UseOutsideClick(ref, () => {
    if (showList) {
      setShowList(false)
    } else if (accountClicked) {
      setAccountClicked(false)
    }

    if(orderId){
      window.location.reload()
    }

  });

  const sendOtpRequest = async (mobileNum) => {

    let obj = {
      phone: Number(mobileNum)
    }

    if (mobileNum.length === 10) {
      const response = await loginOtpGenerate(obj)
      if (response && response?.ErrorCode === 100) {
        setotp(response?.Response?.otp)
        setIsOtpReceived(true)
      }

    }
  }

  const userLogOut = () => {
    localStorage.removeItem("loginNumber");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDetails");
    setHeaderAddressList([])
    setShowLogoutModal(false)
    navigate("/")
    window.location.reload()
  }

  const handleCartView = () => {
    if (authToken) {
      window.scrollTo(0, 0);
      setShowCart(true)
      getCartListData()
    }
    else{
      toast.error('Please login to continue')
    }
  }

  const getHeaderAddressList = async (checkConditiom) => {
    if (authToken) {
      const response = await userAddressList({})
      if (response.ErrorCode === 0) {
        setHeaderAddressList(response.Response)
        getCartItemsCount()
    if( !localStorage.getItem('address') || checkConditiom){
        const address = (response.Response).map((v => {
          if(v.is_default === 1){
            localStorage.setItem("pincode", v.pincode);
            return `${v.name} ${v.landmark} ${v.address} ${v.city ? v.city : ''} ${v.pincode} India.`
          }
        } ))
        localStorage.setItem("address", address);
        setAddress('')
      }
      }
    }
  }

  const checkDeliveryAvailablity = async(pin) => {
      const locationCode = pin ? pin : pincode
      const {ErrorCode} = await pincodeDeliveryEstimate({pincode:locationCode});
      if(ErrorCode == -116 ){
        setDataUnAvailable(true);
        setShowHeaderAddress(false)
      }
      else{
        window.location.reload(true);
      }
    }


  const setSuggestionProductData = (e) =>{
    suggestedproduct[e.target.name] = e.target.value

    // console.log(suggestedproduct.product, suggestedproduct.brand, suggestedproduct.quantity)
  }

  const sendCustomerRequestForProduct = async() =>{
    props.setProductSuggestion(false)
    const fromData = new FormData()
    fromData.append("product_name", suggestedproduct.product)
    fromData.append("brand_name", suggestedproduct.brand)
    fromData.append("quantity", suggestedproduct.quantity)

    const res = await customerRequestProduct(fromData)
    if(res.ErrorCode === 0){
      toast.success('Product requested succefully')
    }
  }

  const handleCartAddressChange = () =>{
    setShowCart(false)
    setShowHeaderAddress(true)
  }

  const gotoProductPage = (data) => {
    // let name = data.product_name.replace(/ /g, "-");
    let name = data.item_slug.replace(/ /g, "-");
    name = name.replace(/\//g, "-");
    navigate(`/product/${name}/${data.product_id}`);
  }

return (
    <>
      {showLoader ? <Loader bottom="50%" right="47%" /> : null}
      {showCart ? <div className="overlay"></div> : null}
      <div className="common_header_container d-block">
      <header className={`Header__HeaderContainer-sc-hejxrh-1 etwMDu common_header pe-3 px-lg-0 ${props.noShadow ? 'noShadow' : ''}`}>
        <div className="Header__HeaderLeft-sc-hejxrh-2 gUZOTl">
          <a className="BlinkitLogo__LogoContainer-sc-xocpjl-0 jzxMvs d-none d-md-flex" href="/">
            <img src={logo} height="100" width="150" alt="logo" />
          </a>
          <a className=" d-md-none header_mobile_logo ps-2 pe-1" href="/">
            <img src={mobileLogo} alt="logo" />
          </a>
          <div className="VerticalDivider__Divider-sc-9aw8k8-0 jdUpJX"></div>
          <div className="LocationBar__Container-sc-x8ezho-6 gcLVHe ps-1 ps-md-3 ps-lg-0" onClick={() => { setShowHeaderAddress(true) }} >
            <div className="LocationBar__EtaContainer-sc-x8ezho-7 kNBeOI">
              <div className="LocationBar__Title-sc-x8ezho-8 dYIqdm font_sub_heading" >
                Delivery {deliveryTime?.delivery_expected_time}
              </div>
              <div className="LocationBar__SubtitleContainer-sc-x8ezho-9 jWpzvj">
                <div className="LocationBar__Subtitle-sc-x8ezho-10 bdWwbr font_paragraph">
                {authToken && address && pincode ?
                       <span  title={`${address} ${pincode}`}> {`${address} ${pincode}`}</span> :<>
                  {!authToken && pincode ? `${address} ${pincode}` :
                    <>
                      {headerAddressList.length ? headerAddressList.map((value) => {
                        if (value.is_default === 1) {
                          return (<span  title={`${value.name} ${value.landmark} ${value.address} ${value.city ? value.city : ''} ${value.pincode} India.`}>{`${value.name} ${value.landmark} ${value.address} ${value.city ? value.city : ''} ${value.pincode} India.`}</span> )
                        }
                      }) : <p className="m-0 font_paragraph">Click there to set your address</p>}
                    </>
                    }</>}
                </div>
                <div className="LocationBar__ArrowContainer-sc-x8ezho-3 IiwNg">
                  <div className="LocationBar__DownArrow-sc-x8ezho-5 fqbcdJ"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="DeprecatedSearchBar__Container-sc-p2db94-0 erFQFR d-none d-lg-flex">
          <div className="Search__SearchContainer-sc-n4jl9s-5 bmPYXe">
            <div open="" className="Search__AutosuggestWrapper-sc-n4jl9s-4 iOinvE">
              <div className="react-autosuggest__container">
                <div
                  data-test-id="search-box"
                  className="Search__SearchBar-sc-n4jl9s-3 jHWud d-none d-lg-flex"

                >
                  <input
                    type="text"
                    autoComplete="off"

                    className="react-autosuggest__input fs-6"
                    placeholder="Search  gutta percha, files & more"
                    onFocus={() => {getTrendingProdu()}}
                    onChange={(e) => { getSearchProducts(e.target.value) }}

                  />
                  <div className="Search__SearchIconButton-sc-n4jl9s-1 cKbDPq ps-2">
                    <img src="/search.png" />
                  </div>
                  {showList ? <span onClick={() => { setShowList(false) }}><i class="fa-solid fa-xmark text-secondary fs-5 position-absolute top-2 cursor-pointer p-2 rounded hover:shadow"></i></span> : null}
                </div>
                {showList ? <div className="suggestion_Container py-3" ref={ref}>
                  <div className="heading font_13 text-secondary ms-2 mb-1 font_sub_heading">{trending ? 'Trending' : 'Products' }</div>

                  { list.length ? list.map((value, index) => {
                    return <div className="product_list bg-white d-flex align-items-center" onClick={() => { gotoProductPage(value)}} key={index} >
                      <div className="pro_img mx-2 mb-1">
                        <img src={value.product_image ? value.product_image : proImage} alt=""  />
                      </div>
                      <p className="product_name font_13 m-0 mb-1 text-secondary cursor-pointer font_paragraph">{value.product_name}</p>
                    </div>
                  }) : 
                  
                  <div className="search_suggetion_product mt-3">
                    <div className="m-3 rounded bg_green text-white">
                        <p 
                          className="m-0 px-3 py-2 font_paragraph cursor-pointer"
                          onClick={()=>{props.setProductSuggestion(true)}}
                        >
                          Didn't find your product click to suggest.
                        </p>
                    </div>
                  </div>

                  }

                </div> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="Header__HeaderRight-sc-hejxrh-3 knMbSB">
          <div className="ProfileButton__Container-sc-975teb-3 liSUTa">
            {authToken ?
              <div className="ProfileButton__Text-sc-975teb-2 bFHCDW d-flex justify-content-center align-items-center fs-5 position-relative" onClick={() => { setAccountClicked(true) }}>
                <div className="user_details d-flex">
                  <img src={props.userDetails && props.userDetails.profile_image ? props.userDetails.profile_image : userImage} alt="" className="profile_img pe-1 rounded-circle" />
                  <p className="m-0 font_sub_heading d-none d-lg-flex">My Account</p>
                </div>
                <img src={ArrowDown} className="d-block w-5 ms-1" alt="" />
                {accountClicked ?
                  <div className="acount_Dropdown position-absolute py-3 bg-white" ref={ref}>
                    <div className="acount_Dropdown_heading fs-5 fw-bold mb-2 px-3 d-flex flex-column font_sub_heading">
                      My Account {" "}
                      <span className="fs-6 font_500 text-secondary font_paragraph">{props.userDetails && props.userDetails.username ? props.userDetails.username : "Username"}</span>
                      <span className="fs-6 font_500 text-secondary font_paragraph">{loginNumber}</span>
                    </div>
                    <Link
                      className="target_link text-secondary m-0 fs-6 px-3 py-1 d-block font_basic"
                      to="/account/profile"
                      onClick={() => { setAccountClicked(false) }}
                    >
                      Profile
                    </Link>
                    <Link
                      className="target_link text-secondary m-0 fs-6 px-3 py-1 d-block font_basic"
                      to="/account/addresses"
                      onClick={() => { setAccountClicked(false) }}
                    >
                      Saved Address
                    </Link>
                    <Link
                      className="target_link text-secondary m-0 fs-6 px-3 py-1 d-block font_basic"
                      to="/account/order"
                      onClick={()=> { setAccountClicked(false) }}
                    >
                      Orders
                    </Link>
                    
                    {/* <p className="target_link text-secondary m-0 fs-6 px-3 py-1 d-flex justify-content-between">
                      <span>My Wallet</span>
                      <span>₹0</span>
                    </p> */}
                    {/* <p className="target_link text-secondary m-0 fs-6 px-3 py-1 d-block">FAQ's</p> */}
                    <p className="target_link text-secondary m-0 fs-6 px-3 py-1 d-block font_basic" onClick={()=>{setShowLogoutModal(true)}}>Log Out</p>
                  </div>
                  : null}
              </div>
              :
              <div className="ProfileButton__Text-sc-975teb-2 bFHCDW font_basic fs-6" onClick={() => { setShowLoginModal(true) }}>Login</div>
            }
          </div>
          <div className="CartButton__Container-sc-1fuy2nj-3 eOczDn me-3 d-none d-lg-flex">
            <div
              className="CartButton__Button-sc-1fuy2nj-5 joEvaa cursor-pointer"
              onClick={handleCartView}
            >
              <div className="CartButton__CartIcon-sc-1fuy2nj-6 iyUoPU">
                <img src="/cart.png" />
              </div>
              <div className="CartButton__Text-sc-1fuy2nj-4 iQAgjV font_basic">{badgeCount ? <span>{badgeCount} Items <br/>₹ {badgePrice}</span> : "My Cart" } </div>

              {/* <span class="position-absolute top-4 px-3 py-2 translate-middle badge rounded-pill bg-secondary font_family_common cart_badge">
                {badgeCount ? badgeCount : 0 }
                <span class="visually-hidden">unread messages</span>
              </span> */}

            </div>
          </div>
        </div>
      </header>

      <div className=" responsive_search_bar bg-white d-lg-none">
          <div className="pt-2">
            <div open="" className="Search__AutosuggestWrapper-sc-n4jl9s-4 iOinvE">
              <div className="react-autosuggest__container">
                <div
                  data-test-id="search-box"
                  className="Search__SearchBar-sc-n4jl9s-3 jHWud d-lg-none"

                >
                  <input
                    type="text"
                    autoComplete="off"

                    className="react-autosuggest__input fs-6"
                    placeholder="Search for gutta percha, files & more"

                    onChange={(e) => { getSearchProducts(e.target.value) }}

                  />
                  <div className="Search__SearchIconButton-sc-n4jl9s-1 cKbDPq ps-2">
                    <img src="/search.png" />
                  </div>
                  {showList ? <span onClick={() => { setShowList(false) }}><i class="fa-solid fa-xmark text-secondary fs-5 position-absolute top-2 cursor-pointer p-2 rounded hover:shadow"></i></span> : null}
                </div>
                {showList ? <div className="suggestion_Container py-3" ref={ref}>
                  <div className="heading font_13 text-secondary ms-2 mb-1 font_sub_heading">{trending ? 'Trending' : 'Products' }</div>

                  {(list || []).map((value, index) => {
                    return <div className="product_list bg-white d-flex align-items-center py-2"  onClick={() => { gotoProductPage(value)}} key={index}>
                      <div className="pro_img mx-2 mb-1">
                        <img src={value.product_image ? value.product_image : proImage} alt=""  />
                      </div>
                      <p className="product_name font_13 m-0 mb-1 text-secondary cursor-pointer font_paragraph">{value.product_name}</p>
                    </div>
                  })}

                </div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showLogoutModal ? 
        <LogoutPopup
          showLogoutModal={showLogoutModal}
          setShowLogoutModal={setShowLogoutModal}
          userLogOut={userLogOut}
        />
        :
        null
      }

      {showLoginModal ?
        <CommonModal
          setShowLoginModal={setShowLoginModal}
          showLoginModal={showLoginModal}
          getOtp={(data) => { sendOtpRequest(data) }}
          isOtpReceived={isOtpReceived}
          setIsOtpReceived={setIsOtpReceived}
          setIsUserLogin={setIsUserLogin}
          isUserLogin={isUserLogin}
        /> : null}

        <SideCartView
          setReorderOpenCart={props.setReorderOpenCart}
          handleCartAddressChange={handleCartAddressChange}
          showCart={showCart}
          setShowCart={setShowCart}
          cartListData={cartListData} 
          getCartListData={getCartListData} 
          setCartListData={setCartListData}
          setOrderId={setOrderId}
          getCarouselData={props.getCarouselData}
          setBadgeCount={setBadgeCount}
          setBadgePrice={setBadgePrice}
          getRecentOrders={props.getRecentOrders}
          loadCarousalData={props.loadCarousalData}
          getProductDetails={props.getProductDetails}
          onCartEmpty={props?.onCartEmpty}
          />

      {showHeaderAddress ?
        <AddressModal
          getCartListData={getCartListData}
          cartListData={cartListData}
          setCartListData={setCartListData}
          showHeaderAddress={showHeaderAddress}
          setHeaderAddressList={setHeaderAddressList}
          setShowHeaderAddress={setShowHeaderAddress}
          headerAddressList={headerAddressList}
          setOpenAddAddressModal={(value)=>{
            setAddAddressModal(value);
          }}
          openAddAddressModal={addAddressModal}
          oldAddressData={[]}
          clearUserAddress={()=>{}}
          getCoordinates={getCoordinates}
          getUserAddressList={getHeaderAddressList}
          onOpenAddAddressModal
          checkDeliveryAvailablity = {checkDeliveryAvailablity}
        /> : null}


        {dataUnAvailable ? <div className={`modal fade address_modal ${dataUnAvailable ? "show" : ''}`} style={{ background: dataUnAvailable ? 'rgba(0,0,0,0.5)' : '', display: dataUnAvailable ? "block" : "none" }} >
                <div className="modal-dialog modal-lg modal_custom_width header_address_alignment" ref={ref}>
                    <div className="modal-content p-4">

                            <div className="modal-header d-flex justify-content-between py-2 px-0 border-bottom-0">
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal"
                                    onClick={()=>{
                                      setDataUnAvailable(false);
                                      setShowHeaderAddress(true);

                                    }}
                                    ></button>
                            </div>
                      <div className="pro_img d-flex justify-content-center">
                        <img src={noData} alt=""  />
                      </div>
                        <div className="modal-header d-block p-4 border-bottom-0">
                            <h5 className="modal-title text-center font_sub_heading" id="staticBackdropLabel">Oh No!</h5>
                            <p className="m-0 text-center text-secondary font_paragraph">We are not delivering in provided location.</p>
                        </div>
                    </div>
                </div>
            </div>
                : null}

      {orderId ? <div className={`modal fade address_modal ${orderId ? "show" : ''}`} style={{ background: orderId ? 'rgba(0,0,0,0.5)' : '', display: orderId ? "block" : "none" }} >
          <div className="modal-dialog modal-dialog-centered" ref={ref}>
              <div className="modal-content" style={{backgroundColor:'#f5f5f5'}}>
                  <div className="modal-header d-block p-3 border-bottom-0 d-flex justify-content-end">
                      <button 
                        type="button" 
                        className="btn-close" 
                        data-bs-dismiss="modal"
                        onClick={()=>{window.location.reload()}}
                      ></button>
                  </div>
                  <div className="modal-body p-0 pb-2">
                      <div className="d-flex justify-content-center">
                          <img src={orderSucess} height="200" width="200" alt="logo" />
                      </div>
                      <div className="content mx-2 mb-5 mt-3 text-center">
                          <p style={{fontSize:25}} className="m-0 font_500 text-success text-center">Your order has been placed</p>
                          <p>Expected Delivery {deliveryTime?.delivery_expected_time}</p>
                          <div>
                            <p>Order Details</p>
                            <div className=" bg-white p-3 mx-3">
                                <p className="mb-2"> ID : {orderId}</p>
                                {
                                  cartListData?.items?.map((item)=>(<div className="d-flex flex-column ">
                                    <div className="d-flex justify-content-between m-0" >
                                      <span className="font-bold text-start">{item?.product_name}</span>
                                      <span className="font-bold">₹{item?.amount}</span>
                                    </div>
                                    <div className="d-flex text-primary p-0 m-0">
                                      QTY {item?.quantity}
                                    </div>
                                    <div className="d-flex text-muted p-0 m-0">
                                    ₹{item?.offer_price}&nbsp;EACH
                                    </div>
                                    </div>))
                                }
                                <div>
                                <div className="d-flex justify-content-between m-0 mt-2" >
                                      <span className="text-muted">Delivery Charges</span>
                                      <span className="text-muted">₹{cartListData?.delivery_fee}</span>
                                </div>
                                <div className="my-2" style={{
                                  height: 1,
                                  width: "100%",
                                  borderTop: '1px dashed gray'
                                }}></div>
                                <div className="d-flex justify-content-between m-0"  style={{height:20}}>
                                      <span className="text-primary">Total Amount:</span>
                                      <span className="font-bold">₹{cartListData?.total}</span>
                                </div>
                                </div>


                            </div>
                            <div>
                              <div className="text-primary m-0">Cash On Delivery</div>
                              <div style={{fontSize:22}} className="text-primary m-0">₹{cartListData?.total}</div>
                            </div>
                      
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> : null }
        <div className="view_cart_tab bg-white p-3 py-2 w-100 d-lg-none" onClick={handleCartView}>
            <div className="cart_tab bg_green rounded row m-0 py-1">
                  <div className="col-8">
                    <p className="m-0 text_paragraph font_13 text-white">{badgeCount} items</p>
                    {cartListData.total && 
                    
                    <p className="m-0 text_paragraph font_13 text-white">₹ {Math.floor(cartListData.total)} with taxes</p>
                    }
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-center text-white">
                      View Cart
                  </div>
            </div>
        </div>

      {/* productSuggestion */}

      <div className={`modal fade address_modal ${props.productSuggestion ? "show" : ''}`} data-bs-backdrop="static" style={{ background: props.productSuggestion ? 'rgba(0,0,0,0.5)' : '', display: props.productSuggestion ? "block" : "none" }} ref={ref}>
          <div className="modal-dialog modal-dialog-centered modal-lg modal_custom_width" ref={ref}>
              <div className="modal-content px-4 py-2">
                  <div className="modal-header d-block py-2 px-0 border-bottom-0 d-flex justify-content-between">
                      <h5 className="fs-6 font_paragraph" id="staticBackdropLabel">Didn't find what you are looking for? Please suggest the product.</h5>
                      <button 
                        type="button" 
                        className="btn-close" 
                        data-bs-dismiss="modal"
                        onClick={()=>{props.setProductSuggestion(false)}}
                        ></button>
                  </div>
                  <div className="modal-body p-0 pb-2">
                      <label htmlFor="" className="form-label font_13 text-secondary"> Product name</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        name="product" 
                        placeholder="" 
                        onChange={(e)=>{setSuggestionProductData(e)}}
                      />
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="" className="form-label font_13 text-secondary"> Quantity</label>
                          <input 
                            type="number" 
                            className="form-control" 
                            name="quantity" 
                            placeholder="" 
                            onChange={(e)=>{setSuggestionProductData(e)}}
                          />
                        </div>
                        <div>
                          <label htmlFor="" className="form-label font_13 text-secondary"> Brand</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            name="brand" 
                            placeholder="" 
                            onChange={(e)=>{setSuggestionProductData(e)}}
                          />
                        </div>
                      </div>
                  </div>
                  <div>

                  </div>
                  <button
                      type="button"
                      className={`${suggestedproduct ? "bg_green" : "bg_disable"} text-white px-2 py-2 mt-3 mb-2  rounded w-4/12`}
                      onClick={sendCustomerRequestForProduct}
                      disabled={suggestedproduct ? false : true }
                  >
                      Send
                  </button>
              </div>
          </div>
      </div>


      <style jsx>
        {`

        body{
          overflow: ${showCart ? 'hidden' : ''};
        }

        .view_cart_tab{
          z-index: 1000;
          position: fixed ;
          left:0px;         
          bottom:0px ;
          border: none !important;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .responsive_search_bar{
          margin-top: 90px;
          position:fixed;
          z-index: 999;
          top:-4px;
          left:0px;
          padding: 0 15px 5px 15px ;
          width:100%;
          background:white ;
        }

        .cart_badge{
          right: -20px ;
        }

        .overlay{
          width: 100%;
          height: 100%;
          background: rgba(0,0,0, 0.6);
          position: absolute;
          top: 0;
          z-index: 10000;
          left: 0;
        }

        .delete_item_btn img{
          height:20px;
          width:20px;
        }

        .cart_container{
          height: calc(100vh - 203px);
          overflow: auto;
        }

        .cart_container::-webkit-scrollbar{
          width:8px;
          color:gray;
        }

        .cart_container::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .cart_container::-webkit-scrollbar-thumb {
            background: gray;
            border-radius:10px;
        }

        .right_cart_list{
          z-index: 10000 ;
        }

          .right_cart_list .offcanvas-header{
            border-bottom: 11px solid #EEEEEE !important;
          }

          .offcanvas-body .total_amount{
            right: 10px ;
            bottom: 20px ;
            width: 97%;

          }

          .total_amount p .proceed_btn_arrow{
            height:14px ; 
            width:14px;
            margin:2px 0 0 5px;
          }

          .empty_cart_view{
            height: 72vh;
          } */}

          .acount_Dropdown{
            width:250px;
            z-index:100000;
            top:50px;
            position:absolute !important;
            border-radius:0 0 15px 15px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          }

          .acount_Dropdown .target_link:hover{
            background-color: #F7F7F7 !important;
          }

          .suggestion_Container{
            height:400px;
            overflow-y:scroll;
            z-index:100000 !important;
            background:#F8F8F8;
          }

          .suggestion_Container::-webkit-scrollbar {
            display: none;
          }


          .suggestion_Container .pro_img{
            height: 30px !important;
            width: 35px !important;
          }
          .suggestion_Container .product_list{
            height:45px;
          }

          .suggestion_Container .product_list:hover{
            background-color: #F6F6F6 !important;
            cursor:pointer;
          }

          .user_details .profile_img{
            height: 30px;
            width: 34px;
          }

          header {
            left: 0;
            top:0;
            background-color: white;
            height: 86px;
            position: fixed;
            width: 100%;
            z-index: 1000;
            box-shadow: rgb(0 0 0 / 7%) 0px 0px 10px 0px;
            display: flex;
            flex-wrap: nowrap;
          }

          .noShadow{
            box-shadow: none !important;
          }

          .cart_list .cart_list_item{
            height: 75px;
            width: 75px;
          } 

          .cart_list .cart_list_item img{
            height: 65px;
            width:75px;
          }

          .gUZOTl {
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            width: auto;
          }
          .jzxMvs {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 86px;
            width: 178px;
            display: flex;
          }
          .jdUpJX {
            width: 1px;
            display: flex;
            background-color: rgb(242, 242, 242);
            height: 100%;
          }
          .gcLVHe {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 86px;
            display: flex;
            font-size: 16px;
            flex-direction: column;
            cursor: pointer;
          }

          @media (max-width: 900px){
            header {
            left: 0;
            top:0;
            background-color: white;
            height: 86px;
            position: fixed;
            width: 100%;
            z-index: 1000;
            box-shadow: rgb(0 0 0 / 7%) 0px 0px 10px 0px;
            display: flex;
            flex-wrap: nowrap;
          }
          }

          @media (min-width: 1260px) {
            .gcLVHe {
              width: 320px;
            }
          }
          .kNBeOI {
            align-items: flex-start;
          }
          .jWpzvj {
            display: flex;
            flex-direction: row;
          }
          .dYIqdm {
            font-size: 18px;
            color: rgb(0, 0, 0);
            font-weight: 700;
            margin-bottom: 6px;
          }
          .bdWwbr {
            font-size: 14px;
            color: rgb(0, 0, 0);
            font-weight: 400;
            clear: both;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            width: auto;
            max-width: 210px;
            text-overflow: ellipsis;
          }
          .IiwNg {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
          }
          .fqbcdJ {
            width: 0px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid rgb(0, 0, 0);
            margin-left: 8px;
          }
          .erFQFR {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            flex: 1 1 0%;
            position: relative;
          }
          .bmPYXe {
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(238, 238, 238);
            box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
            border-radius: 8px;
            overflow: hidden;
            top: 18px;
            position: absolute;
            height: auto;
            z-index: 2001;
            width: 95%;
          }
          @media (min-width: 1260px) {
            .bmPYXe {
              width: 98%;
              left: 10px;
            }
          }
          .jHWud {
            position: relative;
          }
          .iOinvE .react-autosuggest__input {
            border-radius: 8px;
            box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
            border: 0px;
            box-sizing: border-box;
            font-size: 12px;
            height: 50px;
            margin-right: -43px;
            outline: 0px;
            padding: 0px 16px 0px 48px;
            width: 100%;
          }

          .bmPYXe input {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .cKbDPq {
            color: rgb(153, 153, 153);
            font-size: 24px;
            height: 50px;
            width: 40px;
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-family: CustomFont;
          }
          .cKbDPq img {
            width: 32px;
          }

          .iOinvE .react-autosuggest__suggestions-container {
            background-color: rgb(255, 255, 255);
            border-radius: 3px;
            color: rgb(102, 102, 102);
            display: none;
            width: 100%;
            font-size: 12px;
            font-weight: normal;
          }
          .knMbSB {
            margin-left: auto;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            top: 0px;
            right: 0px;
            padding: 0px;
            gap: 5px;
          }
          .liSUTa {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 86px;
            display: flex;
            cursor: pointer;
            transition: background-color 0.5s ease 0s;
          }
          @media (min-width: 1260px) {
            .liSUTa {
              width: 162px;
            }
          }

          .bFHCDW {
            font-size: 18px;
            font-weight: 400;
            color: rgb(54, 54, 54);
            font-family: Okra-Regular;
            transition: font-size 0.5s ease 0s;
          }
          .eOczDn {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 86px;
            display: flex;
          }
          @media (min-width: 1260px) {
            .eOczDn {
              margin-right: 32px;
              margin-left: 12px;
            }
          }
          .joEvaa {
            background-color: rgb(12, 131, 31);
            border-radius: 8px;
            cursor: pointer;
            height: 52px;
            width: 112px;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
          }
          .iyUoPU img {
            width: 26px;
            margin-right: 10px;
          }
          .iQAgjV {
            font-size: 14px;
            color: rgb(255, 255, 255);
            font-family: Okra-Bold;
          }
          .gcLVHe:hover {
            background-color: rgb(252, 252, 252);
        }
        .liSUTa:hover {
            background-color: rgb(252, 252, 252);
        }
        .jzxMvs:hover {
            background-color: rgb(252, 252, 252);
        }

       
        `}
      </style>
    </>
  );
};
export default Header;

