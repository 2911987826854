import React, { useState, useRef } from "react";
import ArrowLeft from "../../../assests/arw_left.png"
import { 
    cashOnDelivery, 
    createOrder, 
    transactionUpdate 
} from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify"
import COD_img from "../../../assests/cod_image.jpg"
import UPI_img from "../../../assests/upi_img.png"
import "./selectPaymentMode.css"
import 'reactjs-popup/dist/index.css';
import Loader from "../loader/loader";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";

const SelectPaymentMode = ({ selectPaymentMode, setOrderId, setSelectPaymentMode, setShowCart, cartListData, getCartListData }) => {

    const authToken = localStorage.getItem("authToken") || "";
    const Razorpay = useRazorpay();
    const [selectedImg, setSelectedImg] = useState("");
    const [showLoader, setShowLoader] = useState(false)

    const [orderBtnDisabled, setOrderBtnDisabled] = useState(false);

    const placeOrderCOD = async () => {
        setOrderBtnDisabled(true);
        setShowLoader(true)
        const response = await cashOnDelivery({})
        if (response.ErrorCode === 0) {
            setOrderId(response.Response.order_id)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
            // toast.success("Order Placed Successfully.")
            // setTimeout(()=>{window.location.reload()}, 3000)

        }
        else if (response.ErrorCode === -100) {
            toast.error("Your Cart Is Empty")
            setOrderBtnDisabled(false)
            setSelectedImg(false)
        }
        else if (response.ErrorCode === -111) {
            toast.error("Some of cart items are out of stock")
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }
    }

    const backToCartView = () => {
        setSelectPaymentMode(false)
        setShowCart(true)
    }

    const handlePaymentOnline = useCallback(async () => {
        setOrderBtnDisabled(true)
        setShowLoader(true)
        const formData = new FormData();
        formData.append("token", authToken)

        const order = await createOrder(formData);
        if(order.ErrorCode === 0){
            setShowLoader(false)
            const options = {
                key: "rzp_live_bXcp9inxQER7yv",
                amount: order.Response.razorpay_order.amount,
                currency: order.Response.razorpay_order.currency,
                name: order.Response.razorpay_order.name,
                description: order.Response.razorpay_order.description,
                image: order.Response.razorpay_order.image,
                order_id: order.Response.razorpay_order.id,
                handler: (res) => {
                    // console.log(res);
                    if(res){
                        updateTransactionByIds(res)
                    }
                },
                prefill: {
                    name: "Instadent",
                    email: "test123@gmail.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Instadent Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };
    
            const rzpay = new Razorpay(options);
            rzpay.open();
        }
        else{
            toast.error("Some of cart items are out of stock")
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }

    },[Razorpay]);

    const updateTransactionByIds = async(data) =>{
        const formData = new FormData()
        formData.append("razorpay_order_id", data.razorpay_order_id)
        formData.append("razorpay_payment_id", data.razorpay_payment_id)
        // console.log(data)
        // console.log(formData)
        const response = await transactionUpdate(formData)
        if(response.ErrorCode === 0){
            setShowCart(false)
            setSelectPaymentMode(false)
            setOrderId(response.Response.idc_order_id)
        }
    }

    return (
        <>
            {selectPaymentMode ? <div className="overlay"></div> : null}
            <div
                className={`offcanvas offcanvas-end right_cart_list ${selectPaymentMode ? "show visible" : null}`}
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            >
                <div className="offcanvas-header mb-1 d-flex align-items-center">
                    <img
                        src={ArrowLeft}
                        alt=""
                        height="20px"
                        width="22px"
                        className="cursor-pointer mb-2"
                        onClick={backToCartView}
                    />
                    <p className="m-0 fs-5 font_500 pb-2">Bill Total : <span className="fs-6">  ₹ {Math.floor(cartListData.total_price) + Math.floor(cartListData.delivery_fee)}</span></p>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => { setSelectPaymentMode(false) }}
                    >
                    </button>
                </div>
                <div className="offcanvas-body p-0">
                    <div className="top_div border-bottom px-3 pb-1">
                        <span className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0 fw-bold py-1 lh-sm">Select Payment Mode</h6>
                        </span>
                    </div>

                    <div className="cart_container_select_payment">
                        <div className="total_bill_amount px-3 rounded">

                            <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">Pay Online</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "Online" ? "img_select" : ""} `}>
                                        <img
                                            src={UPI_img}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("Online")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">Cash On Delivery</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "Cod" ? "img_select" : ""} `}>
                                        <img
                                            src={COD_img}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("Cod")}
                                        />
                                    </div>
                                </div>
                                <div className=" total_amount position-absolute bg-white pt-2">
                                    <button
                                        type='button'
                                        className='px-3 py-2 ms-3 bg_green mt-2 rounded text-white d-block place_order_btn'
                                        onClick={selectedImg === "Cod" ? placeOrderCOD : handlePaymentOnline}
                                        disabled={orderBtnDisabled || !selectedImg}
                                    >
                                        Place Order
                                    </button>

                                </div>

                                {showLoader ? <Loader bottom="50%" right="47%" /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                
                body{
                    overflow: ${selectPaymentMode ? "hidden" : ''}
                }
                
                `}
            </style>
        </>
    )
}

export default SelectPaymentMode;