import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"
import { Header } from "../shared-components";
import { useNavigate } from "react-router-dom";
import UseOutsideClick from "../shared-components/detectOutsideClick/useOutSideClick"
import "./productCategoryWise.css"
import Footer from "../shared-components/footer/footer";
import productImage from "../../assests/product_img.png";
import {
    getSubCategory,
    categoryData,
    categoryProductList,
    cartItemsCount,
} from "../../utils/apiUrlsSetup";
import Loader from "../shared-components/loader/loader";
import LeftSubCategories from "../shared-components/leftSubCategories/LeftSubCategories"
import CategoryHeader from "../shared-components/categoryHeader/categoryHeader";
import ProductCard from "../shared-components/productCard/productCard";
import ProductDescriptionBox from "../shared-components/productDescriptionBox/productDescriptionBox"

const ProductCategoryWise = () => {

    const { id,Id } = useParams();
    const navigate = useNavigate() ;
    const ref = useRef();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {}
    const authToken = localStorage.getItem("authToken") || "" ;


    const [catProductList, setCatProductList] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryListData, setCategoryListData] = useState([]);
    const [activeSubCategory, setActiveSubCategory] = useState();
    const [moreData, setMoreData] = useState([]);
    const [subHeaderData, setSubHeaderData] = useState([]);
    const [openSelect, setOpenSelect] = useState(false)

    const [categoryId, setCategoryId] = useState()
    const [badgeCount, setBadgeCount] = useState(0)
    const [badgePrice, setBadgePrice] = useState(0)
    const [showLoader, setShowLoader] = useState(false)
    const pincode = localStorage.getItem("pincode") || '' ;

    UseOutsideClick(ref, () => {
        if (openSelect) {
            setOpenSelect(false)
        }
    });

    useEffect(() => {
        getCategoryData()
        window.scrollTo(0,0)
    }, [])

    useEffect(() => {
        if (categoryId) {
            getSubCategoryData(categoryId)
        }
    }, [categoryId])

    useEffect(() => {
        setCategoryId(Number(id));
    }, [])

    const getCartItemsCount = async() =>{
        const res = await cartItemsCount({})
        if(res.ErrorCode === 0){
            
            // getCategoryProductList(subCatId)
            setBadgeCount(res?.Data?.total_count)
            setBadgePrice(res?.Data?.total_price)
        }
    }

    const getCategoryProductList =  async(id) => {
        setShowLoader(true)
        navigate(`/product-categorywise/${categoryId}/${id}`)
        const obj = {
            category_id: id,
        }
        obj.pincode = pincode

        if(!authToken){
            obj.pincode = pincode
        } 

        let response = await categoryProductList(obj)
        setShowLoader(false)
        if (response && response.ErrorCode === 0) {
            setCatProductList(response.ItemResponse.category_products)
        }
    }


    const getSubCategoryData = async (categoryId) => {
        let obj = {
            parent_id: categoryId
        }
        const response = await getSubCategory(obj);
        if (response && response.ErrorCode === 0) {
            setSubCategories(response.Response);
            setActiveSubCategory(isFinite(Id) ? Id : (response.Response.length && response.Response[0].id))
            getCategoryProductList(isFinite(Id) ? Id : (response.Response.length && response.Response[0].id))
        }
    }

    const getCategoryData = async () => {
        let response = await categoryData({"pincode":pincode})
        if ( response && response.ErrorCode === 0) {
            setCategoryListData(response.Response.category)
        }
        const copyCategoryListData = [...response.Response.category]
        const moreData = copyCategoryListData.splice(8, 50)
        setSubHeaderData(copyCategoryListData)
        setMoreData(moreData)
    }

    const updateCategoryProductList = (subCategoryId) => {
        setActiveSubCategory(subCategoryId)
        getCategoryProductList(subCategoryId)
    }

    return <>
        {showLoader ? <Loader bottom="50%" right="37%" /> : ''}
        <Header 
            noShadow
            userDetails={userDetails}
            badgeCount={badgeCount}
            badgePrice={badgePrice}
        />
        <CategoryHeader
            categoryHeaderData={subHeaderData}
            moreData={moreData}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
        />


        <div className="row m-0 product_cat_wise_main">
            <div className="col-lg-1 col-0"></div>
            <div className="col-12 col-lg-10 border border-0 px-3 p-lg-0">
                <div className=" row m-0 mt-3">
                    <div className="col-4 col-lg-3 side_bar_scroller p-0">
                        <LeftSubCategories
                            activeSubCategory={activeSubCategory}
                            subCategories={subCategories}
                            updateCategoryProductList={updateCategoryProductList}
                        />

                    </div>
                    <div className="col-8 col-lg-9 p-0">
                        <div className="cat_container_main row m-0">
                            <ProductCard 
                                catProductList={catProductList}
                                setCatProductList={setCatProductList}
                                getCartItemsCount={getCartItemsCount} 
                            />
                        </div>
                    </div>
                </div>
                <ProductDescriptionBox />
            </div>
        </div>
        <div className="px-4 mb-5 pb-2">
        <Footer />
        </div>
    </>
}

export default ProductCategoryWise