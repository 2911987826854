import React,{useState, useEffect} from "react";
import { Header,Footer } from "../shared-components";
import { useNavigate, useLocation } from "react-router-dom";
import Brand from "../../assests/brand-image.png"
import "./brandPage.css"

const BrandPage = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    useEffect(()=>{
        const brandData = location.state
        setData(brandData)
    },[])

    const [data, setData] = useState([]);
    const [inputValue, setInputValue] = useState('')
    const [selectValue, setSelectValue] = useState('')

    const searchBrand = (value)=>{
        setInputValue(value)
        const searchTerm = value.toLowerCase()
        const searchedBrands = location.state.filter(v => {
            return v.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
        if(searchTerm === ''){
            setData(location.state)
        }
        else{
            setData(searchedBrands)
        }
    }

    const sortBrands = (type) =>{
        setSelectValue(type)
        const copyData = [...data]
        if(type === "A-Z"){
            const assendingData = copyData.sort((a, b) =>
            a.name > b.name ? 1 : -1,
            )
            setData(assendingData)
        }
        else if(type === "Z-A"){
            const disendingData = copyData.sort((a, b) =>
            a.name > b.name ? -1 : 1,
            )
            setData(disendingData)
        }
    }

    return (
        <>
            <Header
                userDetails={userDetails}
            />
            <div className="row m-0 brand_page_main px-3 px-lg-0">
                <div className="col-0 col-lg-2"></div>
                <div className="col-12 col-lg-8 brands_card_container rounded p-0">
                <div className=" bg-white">
          <div className="pt-4 search_brands_parent row m-0 justify-content-between">
            <div className="Search__AutosuggestWrapper-sc-n4jl9s-4 iOinvE col-12 col-md-4">
              <div className="react-autosuggest__container search_bar_container">
                <div
                  data-test-id="search-box"
                  className="Search__SearchBar-sc-n4jl9s-3 jHWud"
                >
                  <input
                    type="text"
                    autoComplete="off"
                    value={inputValue}
                    onChange={(e)=>{searchBrand(e.target.value)}}

                    className="react-autosuggest__input fs-6"
                    placeholder="Search brand"

                  />
                  <div className="Search__SearchIconButton-sc-n4jl9s-1 cKbDPq ps-2">
                    <img src="/search.png" />
                  </div>
                  { inputValue ? <span onClick={() => {setInputValue('') }}><i class="fa-solid fa-xmark text-secondary fs-5 position-absolute top-2 cursor-pointer p-2 rounded hover:shadow"></i></span> : null}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 brand_sorting mx-3 mt-3 py-2 py-md-0 mt-md-0 mx-md-4  d-flex justify-content-center">
                <select 
                    name="brand" 
                    id="" 
                    value={selectValue}
                    className="form-select border-0 sorting_select cursor-pointer" 
                    onChange={(e)=>{sortBrands(e.target.value)}}
                    >
                    <option value="Sort By" selected disabled>Sort By</option>
                    <option value="A-Z">A-Z</option>
                    <option value="Z-A">Z-A</option>
                </select>
            </div>
          </div>
        </div>

                    <div className="row m-0 py-4 px-2 pb-4">
                        {data && data.length ? data.map((value, index) => {
                            return <div className="col-2 text-center brand_card_parent mt-3 cursor-pointer" 
                                    key={index}
                                    onClick={()=>{navigate(`/brand/${value.name}/${value.id}`)}}
                                    >
                                <div className="brand_card_image border rounded ms-1">
                                    <img src={value.product_image ? value.product_image : Brand} alt="" />
                                </div>
                                <p className="m-0 text-center mt-2 font_500">{value.name}</p>
                            </div>
                        }) : null}
                    </div>
                </div>
                <div className="col-0 col-lg-2"></div>
            </div>
            <Footer/>

        </>
    )

}

export default BrandPage;