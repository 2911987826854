import { pincodeBrand } from "../../../utils/apiUrlsSetup"
import React, { useState, useEffect } from "react";
import "./footer.css"
import productImage from "../../../assests/product_img.png"
import { useNavigate } from "react-router-dom";
import Brand from "../../../assests/brand-image.png"
import FooterTopCard from "./footerTopCard"
import FooterPaymentPartner from "./footerPaymentPartner"
import FooterCategories from "./footerCategories"
import FooterUsefulLinks from "./footerUsefulLinks"
import FooterBottomContent from "./footerBottomContent"

const Footer = () => {

  const navigate = useNavigate()
  const [brandList, setBrandList] = useState([])
  const [topTenBrands, setTopTenBrands] = useState([])

  useEffect(() => {
    getBrandList()
  }, [])

  const getBrandList = async () => {
    const data = await pincodeBrand({})
    if (data.ErrorCode === 0) {
      setBrandList(data.Response)
      // const tenBrands = data.Response.slice(0, 10)
      const tenBrands = data.Response
      setTopTenBrands(tenBrands)
    }
  }

  return (
    <>
      <footer className="Footer__Wrapper-sc-14h3h0g-2 bJfrQB">
        <div className="row m-0">

          <FooterTopCard />
        </div>
        <div className="ms-md-3">
          <div className="FooterLinks__ListHeading-sc-12rhzht-1 aonOx font_heading">About Us</div>
          <div className="font_paragraph mt-4 px-2">
          Alda inspires cooking, with its top-notch appliances that breathe a new life into the kitchens.
<br/>
Some of the world’s best brains have been at work to innovate, design and develop products that, to put it simply, make preparing food as much of a pleasure as eating it!
<br/>

Tweaked to Indian requirements, Alda’s range of Chimneys, Built-in Hobs, Cooking Ranges and Cooktops promise to bring out the culinary artist in you. They make cooking convenient, hassle-free and safe. Making use of the latest innovations and technological advancement, Alda is in synch with the demands of today’s homemaker who wants to prepare a variety of meals, with the minimum of discomfort.
<br/>

Good cookware is a prerequisite for good food... cookware that is safe to cook in, distributes heat evenly and is easy to keep clean. Alda’s international range of Cookware is specially designed by Italian craftsmen for people with a passion for cooking. For our house-proud customers, we also make sure that it looks good and is long lasting.
<br/>

Alda’s range of exclusive, rich crockery is the epitome of elegance; classic, delicate, sophisticated… exquisite crockery with gorgeous rich patterns of gold/platinum, inspirational design, accentuated details and unique elements. An endeavor to bring the best in beauty and quality to your tables to brighten and enrich your dining experience.
So, bring home Alda today and see your kitchen come alive.

          </div>
          {/* <div className="FooterLinks__HeadingContainer-sc-12rhzht-5 bZlgle d-flex justify-content-between">
            <div className="FooterLinks__ListHeading-sc-12rhzht-1 aonOx font_heading">Brands</div>
            <p className="FooterLinks__SeeAll-sc-12rhzht-4 flTNGy cursor-pointer  "
              onClick={() => { navigate(`/brands`, { state: brandList }) }}>
              <span className="float-right font_basic">See All</span>
            </p>
          </div>
          <ul type="row" className="FooterLinks__List-sc-12rhzht-2 ewIa-Dc d-flex">
            {topTenBrands && topTenBrands.length ? topTenBrands.map((item, index) => {
              return <li
              className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN text-center  cursor-pointer ms-1 ms-lg-0"
              key={index}
              onClick={() => { navigate(`/brand/${item.name}/${item.id}`) }}
              > */}
                {/* <div className="brand_image rounded mb-2">
                  <img src={item.product_image ? item.product_image : Brand} alt="" />
                </div> */}
                {/* <a href="" className="">{item.name}</a>
              </li>
            }) : null}
          </ul> */}
        </div> 
        <section className="sc-bZkfAO dcFWhu FooterLinks__Grid-sc-12rhzht-0 kormfg d-block d-md-flex ">
          <FooterUsefulLinks />
          <FooterCategories />

        </section>
        {/* <div className="PaymentsRow__ListHeading-sc-p5takb-4 dtHCZH font_heading">
          Payment partners
        </div>
        <div className="PaymentsRow__Row-sc-p5takb-0 etmxvz">
          <FooterPaymentPartner />
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Net Banking</div>
          </div>
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Cash on Delivery</div>
          </div>
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Instadent cash</div>
          </div>
        </div> */}
      </footer>

      <FooterBottomContent />
      <style jsx>
        {`

        .brand_image{
          width:80px;
          height:80px;
          padding:2px ;
          border: 1px solid gray ;
        }

        .brand_card_ui{
          width:80px !important;
        }

        .brand_image img{
          width:100%;
          height:100%;
        }

        .footer_card_height div{
          border-radius: 12px ;
        }

        .bJfrQB {
            width: auto;
            max-width: 1280px;
            margin: 20px auto 40px;
        }
        .hIMhHn {
            gap: 40px;
            padding-top: 40px;
        }
        .flJZea {
            display: grid;
            max-width: 90rem;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .dFvtUi {
            border-radius: 16px;
            border: 0.5px solid rgb(221, 221, 221);
            padding: 31px;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
        }
        .footer_features_icon {
            background: rgb(249, 249, 249);
            width: 100px;
            height: 100px;
            border-radius: 50px;
            margin-bottom: 24px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
        }
        .iNeJeA {
            width: 100%;
            height: 100%;
        }
        .iZMVhO {
            font-size: 14px;
            text-align: center;
            color: rgb(0, 0, 0);
            font-family: Okra;
            margin-bottom: 8px;
            font-weight: 600;
        }
        .ggXHCa {
            font-size: 12px;
            text-align: center;
            color: rgb(102, 102, 102);
            font-family: Okra;
            margin-bottom: 8px;
            font-weight: 400;
        }
        .eAeyYs {
            font-size: 14px;
            color: rgb(102, 102, 102);
            padding: 40px 0px;
            border-bottom: 1px solid rgb(238, 238, 238);
            font-family: OKRA-REGULAR;
            line-height: 18px;
        }
        .dcFWhu {
            display: grid;
            max-width: 90rem;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr;
        }
        .bZlgle {
            display: flex;
            flex-direction: row;
        }
        .aonOx {
            font-family: Okra;
            font-weight: 600;
            color: rgb(0, 0, 0);
            font-size: 18px;
            margin: 24px 0px;
        }
        .flTNGy {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            padding-left: 16px;
            color: rgb(12, 131, 31);
            font-size: 16px;
        }
        .jPIBOn {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto auto;
            gap: 12px 32px;
        }
        .BvTiN {
            display: inline-block;
            font-size: 14px;
        }
        
        .hOxyYr {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            width: auto;
            background-color: rgb(252, 252, 252);
            max-width: 100%;
        }
        .ewIa-Dc {
            list-style: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 12px 30.5px;
        }
        .jqmuYk {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto;
            gap: 12px 32px;
        }
        .BvTiN {
            display: inline-block;
            font-size: 14px;
        }
        .jqmuYk {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto;
            gap: 12px 32px;
        }
      
        .dtHCZH {
            font-family: Okra;
            font-weight: 600;
            color: rgb(0, 0, 0);
            font-size: 18px;
            margin: 24px 0px;
        }

        .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 4px 19px;
            width: 100%;
        }
        .cqMUOv {
            width: 96px;
            height: 64px;
            border-radius: 4px;
            text-align: center;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            border: 1px solid rgb(242, 242, 242);
        }
        .hKlCIk {
            width: 60px;
            height: 52px;
        }
        .bALhSz {
            font-weight: 500;
        }
        .fBgyNM {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 12px 32px;
        }        
        .eGalFF {
            padding: 16px 0px;
            width: auto;
            max-width: 1280px !important;
        }
        .aeTYn {
            display: grid;
            max-width: 90rem;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .bLeZQJ {
            display: flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            
            height: 100%;
            color: #666;
            font-size: 12px;
            font-family: 'Okra', Helvetica;
        }
        .cPxShR {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }
        .hnVuxl {
            font-family: Okra;
            font-weight: 600;
            font-size: 14px;
            margin: 16px;
        }
        .gyBsbb {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
        }
        .kMTqFF {
            width: 92px;
            height: 30px;
            border-radius: 4px;
            margin: 8px;
            cursor: pointer;
        }
        
        .hIHTNb {
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
            opacity: 0;
            transition: opacity 0.25s ease-out 0s;
            will-change: opacity;
            border-radius: inherit;
            animation: 1.5s linear 0s infinite normal forwards running cypEgR;
        }
        .app-download-icons {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: none;
            opacity: 1;
            will-change: transform, opacity;
            border-radius: inherit;
            filter: brightness(0.95);
            transition: transform 0.4s ease-in-out 0s, filter 0.4s ease 0s, opacity 0.25s ease 0s;
        }
        .jgvGfq {
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }
        .gyBsbb {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
        }
        
        .iSSvsj {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }

        .kfNqsf {
            margin: 0px 12px;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            -webkit-box-align: center;
            align-items: center;
        }

        @media screen and (max-width: 900px){
          .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 8px 42px;
            width: 100%;
            flex-wrap: wrap;
        }

        .kfNqsf {
            margin: 0px 0 0 12px;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            -webkit-box-align: center;
            align-items: center;
        }
      }


        @media screen and (max-width: 600px){
          .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }

        .bJfrQB {
            width: auto;
            max-width: 1280px;
            margin: 0px auto 40px;
        }
        .cqMUOv {
              width: 86px;
              height: 64px;
              border-radius: 4px;
              text-align: center;
              display: flex;
              -webkit-box-pack: center;
              -webkit-box-align: center;
              align-items: center;
              border: 1px solid rgb(242, 242, 242);
          }
      }
        
        `}
      </style>
    </>
  );
};
export default Footer;
