import "./productDescriptionBox.css"
const ProductDescriptionBox = () => {
    return (
        <>
            <div className="bottom_priceList p-5 border border-top-0">
                {/* <p className="m-0 mb-3 fw-bold fs-5 font_sub_heading">Vegetables</p>
                <p className="cat_discription text-secondary lh-sm font_paragraph">
                    Vitamins and other necessary nutrients are abundant in vegetables. Vegetables are an important part of a balanced diet and are necessary for maintaining excellent health. The veggies are a good source of iron (spinach, peas, tomato, bitter gourd), iodine (okra, summer squash), calcium (tomato, spinach, peas), vitamin A (leafy vegetables, pumpkin), vitamin B (peas, spinach), vitamin C (moringa, chilli, tomato), and vitamin K. (leafy vegetables). There are several beneficial components in vegetables. Cheratin, which is found in bitter gourd, is effective against diabetes, while pumpkin and other leafy vegetables are excellent sources of beta carotene. You can buy all these veggies from our website.
                </p> */}
            </div>
        </>
    )
}

export default ProductDescriptionBox